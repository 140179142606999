import React, { useContext } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

import './DialogButton.scss';
import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import craftJsParser from '../../components/utils/craftJsParser';
import Modal from './lib/Modal';
import propertiesByMediaQuery from '../utils/propertiesByMediaQuery';
import { useMediaQuery } from '../../components/utils/useQueryMedia';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    isOpen: false,
    fullscreen: null,
    size: null,
    backdrop: 'true',
    fade: true,
    halign: 'center',
    valign: 'center',
  },
  visibility: {},
};

const DialogButtonDiv = styled.div((props) => props.$styleText);

export const DialogButton = (componentProps) => {
  let props = componentProps;

  const isSM = useMediaQuery('(max-width:576px)');
  const isMD = useMediaQuery('(min-width:768px)');
  const is900px = useMediaQuery('(min-width:900px)');
  const isLG = useMediaQuery('(min-width:992px)');
  const isXL = useMediaQuery('(min-width:1200px)');
  const isXXL = useMediaQuery('(min-width:1400px)');

  let mediaIndicator = 7;
  if (isXXL) {
    mediaIndicator = 6;
  } else if (isXL) {
    mediaIndicator = 5;
  } else if (isLG) {
    mediaIndicator = 4;
  } else if (is900px) {
    mediaIndicator = 3;
  } else if (isMD) {
    mediaIndicator = 2;
  } else if (isSM) {
    mediaIndicator = 1;
  }

  const pageDataContext = React.useContext(PageDataContext);
  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const { properties } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const onToggle = React.useCallback((event) => {
    if (typeof props?.properties?.onToggle === 'function') {
      props.properties.onToggle();
    } else {
      setIsOpen((v) => !v);
    }
  }, [props?.properties?.onToggle]);

  const pValue = React.useMemo(() => {
    if (!isVisible) return { hasError: true, buttonContent: null, content: null };

    let buttonContent = null;
    let content = null;
    let hasError = false;

    try {
      const data = getProjectDataFromContext(pageDataContext);

      const buttonNodeId = props.linkedNodes['dialog-button'];

      if (!buttonNodeId) return null; // something is wrong; the referenced node to render this selected layout doesn't exists

      const contentNodeId = props.linkedNodes['modal-body'];

      if (!contentNodeId) return null;

      const extendProps = {};
      if (typeof props?.properties?.onToggle !== 'function') {
        extendProps.onClick = () => {
          onToggle();
        };
      }

      buttonContent = craftJsParser({
        craftState: data,
        rootNodeId: buttonNodeId,
        pageId: props.refType === 'layout' ? null : props.refId,
        pageLayoutId: props.refType === 'layout' ? props.refId : null,
        pageType: props.refType,
        extendProps,
      });
      content = craftJsParser({
        craftState: data,
        rootNodeId: contentNodeId,
        pageId: props.refType === 'layout' ? null : props.refId,
        pageLayoutId: props.refType === 'layout' ? props.refId : null,
        pageType: props.refType,
      });
    } catch (err) {
      console.error(err);
      hasError = true;
    }

    return { buttonContent, content, hasError };
  }, [
    isVisible,
    pageDataContext,
    props.linkedNodes, props.refId, props.refType,
    props?.properties?.onToggle,
    onToggle,
  ]);

  if (!isVisible) return null;
  if (pValue.hasError) return null;
  //if (!pValue.buttonContent) return null;
  if (!pValue.content) return null;

  let fullscreen = false;

  let fullscreenValue = 0;
  let fls = properties.fullscreen ?? '';
  switch (fls.toLowerCase()) {
    case 'sm':
      fullscreenValue = 1;
      break;
    case 'md':
      fullscreenValue = 2;
      break;
    case '<900px':
      fullscreenValue = 3;
      break;
    case 'lg':
      fullscreenValue = 4;
      break;
    case 'xl':
      fullscreenValue = 5;
      break;
    case 'xxl':
      fullscreenValue = 6;
      break;
  }

  if (mediaIndicator && mediaIndicator <= fullscreenValue) {
    fullscreen = true;
  }
  if (mediaIndicator === '' && properties.fullscreen === 'sm') {
    fullscreen = true;
  }

  const classes = [
    'modal-dialog-button',
    `modal-dialog-halign-${propertiesByMediaQuery(properties, 'halign', mediaIndicator)}`,
    `modal-dialog-valign-${propertiesByMediaQuery(properties, 'valign', mediaIndicator)}`,
  ];

  if (props?.properties?.modalClassName) {
    classes.push(props.properties.modalClassName);
  }

  const render = typeof props?.properties?.onToggle === 'function' ? props.properties.isOpen : isOpen;

  return (
    <DialogButtonDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      {pValue.buttonContent}
      {render && <Modal
        isOpen={typeof props?.properties?.onToggle === 'function' ? props.properties.isOpen : isOpen}
        toggle={onToggle}
        fullscreen={properties.fullscreen === 'true' ? true : properties.fullscreen === 'false' ? false : fullscreen}
        size={properties.size}
        backdrop={properties.backdrop === 'true' ? true : properties.backdrop === 'false' ? false : properties.backdrop === 'static' ? 'static' : true}
        fade={properties.fade}
        rootClassName={props?.properties?.rootClassName ?? null}
        modalClassName={cx(classes)}
        modalDialogClassName={props?.properties?.modalDialogClassName ?? null}
        wrapClassName="wrap-dialog-button"
        backdropClassName="backdrop-dialog-button"
        zIndex={props?.properties?.zIndex ?? null}
        keepScroll={props?.properties?.keepScroll ?? false}
      >
        {pValue.content}
      </Modal>}
    </DialogButtonDiv>
  );
};
