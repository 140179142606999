import { call, delay, put } from 'redux-saga/effects';
import * as actions from './../actions';
import * as LoginMethods from './../../utils/LoginMethods';
import * as EmailValidator from 'email-validator';
import PhoneNumberValidator from './../../utils/PhoneNumberValidator';
import ClientAPI from './../../ClientAPI/ClientAPI';
import { authenticateSaga, clearAuthStorage } from './authentication';
import * as paths from './../../ClientAPI/paths';
import ClientDetection from '../../utils/ClientDetection';
import PlayerAbuseChecker from '../../utils/PlayerAbuseChecker';
import { clearLoyaltyState } from '../../../../store/slices/rankSystems';
import { clearMomentumState } from '../../../momentum/store/actions/momentum';
import { resetRegister } from '@/modules/casino/store/actions/register.js';

const minUserLength = 4;
const minPasswordLength = 6;
const minCodeLenght = 4;
const smsRequestInterval = window.config.resendSMSTimer ?? 20;

let pEnterCount = 0;
export function* loginUsernameSaga(action) {
  let username = action.username.replace(/\s/g, '');
  let allowUsernameButton = false;
  let error = false;
  let method = yield validateMethod(username);

  if (method === LoginMethods.IS_UNDEFINED) {
    error = true;
  }

  if (!error && method > 0) {
    allowUsernameButton = true;
  }

  yield put(actions.setLoginUsername(username, error, method, allowUsernameButton));
}

function* validateMethod(username) {
  const { currentCountry } = ClientAPI.getStore().getState().allCountries;
  let method = LoginMethods.IS_UNDEFINED;

  if (!(username.length > minUserLength)) {
    return method;
  }

  let validateEmail = yield EmailValidator.validate(username);
  if (validateEmail) {
    method = LoginMethods.IS_EMAIL;
    return method;
  }

  let validateNumber = yield PhoneNumberValidator.validate(username, currentCountry);
  if (validateNumber) {
    method = LoginMethods.IS_PHONE;
    return method;
  }

  return method;
}

export function* stepChangeSmsRequest(action) {
  if (!(2 === action.step)) {
    return;
  }

  yield call(requestSmsSaga);
}

export function* requestSmsSaga() {
  const storeState = ClientAPI.getStore().getState();
  if (!storeState.login.allowSMS) {
    return;
  }

  let loginMethod = storeState.login.method;
  if (!(LoginMethods.IS_PHONE === loginMethod)) {
    return;
  }
  let phoneNumber = storeState.login.username;

  const axios = ClientAPI.getInstance();

  try {
    const response = yield axios({
      url: paths.authentication.SMS,
      method: 'post',
      data: {
        phone: phoneNumber,
      },
    });

    if (!response.status) {
      throw new Error('[ERROR] Login SMS missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] Login SMS message not sent!');
    }

    yield put(actions.allowSmsRequest(false));
  } catch (error) {
    console.log(error);
    yield put(actions.allowSmsRequest(true));
    return;
  }

  yield put(actions.startSmsCountdown(smsRequestInterval + 1));
  yield delay(smsRequestInterval * 1000);
  yield put(actions.allowSmsRequest(true));
}

export function* loginPasswordSaga(action) {
  let password = action.password;
  let allowLoginButton = false;
  let error = false;
  let hasMinLength = false;

  const storeState = ClientAPI.getStore().getState();
  const loginMethod = storeState.login.method;

  switch (loginMethod) {
    case LoginMethods.IS_EMAIL:
      if (!(password.length >= minPasswordLength)) {
        hasMinLength = false;
        if (pEnterCount) {
          error = true;
        }
        break;
      }

      hasMinLength = true;
      break;
    case LoginMethods.IS_PHONE:
      if (!(password.length >= minCodeLenght)) {
        hasMinLength = false;
        if (pEnterCount) {
          error = true;
        }
        break;
      }

      hasMinLength = true;
      break;
    default:
      console.log(`[ERROR][LOGIN] LoginMethod is not accepted ${loginMethod}!`);
  }

  if (hasMinLength && !error) {
    allowLoginButton = true;
    pEnterCount++;
  }

  yield put(actions.setLoginPassword(password, error, allowLoginButton));
}

export function* requestUserAuthentication(action) {
  const storeState = ClientAPI.getStore().getState();
  let method = storeState.login.method;

  const { currentCountry, countries } = storeState.allCountries;
  const selectedCountry =
    countries && Array.isArray(countries) && countries.find((country) => country.countryCode === currentCountry);

  yield put(actions.inRequest(true));

  let authData = {};

  const axios = ClientAPI.getInstance();

  let data = ClientDetection.getAllInfo();
  if (LoginMethods.IS_SEAMLESS_TOKEN !== method) {
    data.username =
    currentCountry && currentCountry !== 'RO'
      ? '' + (selectedCountry.phoneCode ?? '') + storeState.login.username // add country code to phone number if not RO
      : storeState.login.username;

    data.password = storeState.login.password;
  } else {
    data.token = storeState.login.password;
  }
  data.platformType = window.config.platformType;

  // console.log('DEBUG [AUTH]SAGA User auth data: ', { data, storeState, action });

  let pac = PlayerAbuseChecker.getInfo();
  data = {
    ...pac,
    ...data,
  };

  try {
    const response = yield axios({
      url: method === LoginMethods.IS_SEAMLESS_TOKEN ? paths.authentication.SEAMLESS_LOGIN : paths.authentication.AUTH,
      method: 'post',
      data: data,
    });

    // console.log('DEBUG [AUTH]SAGA User auth data RESPONSE: ', { response, action });

    if (!response.status) {
      console.log('[AUTH] User auth error');
      yield put(actions.inRequest(false));
      yield put(actions.loginStep(1));
      yield put(actions.loginError(true));
      return;
    }

    // check if users migrations process is running and redirect to migration page
    // TODO: UPDATED THE CONDITIONS FOR IF WHEN THE API IS IMPLEMENTED AND WE KNOW THE CORRECT RESPONSE
    if (
      response.result &&
      response.result === 'SOMETHING TO MATCH THE API RESPONSE HERE' // TODO: UPDATE THIS CONDITION
    ) {
      console.log('[AUTH] User migration is in progress');
      yield put(actions.usersMigrationInProgress(true));
      return;
    }

    // check if the user details we're migrated and we need to reset the password for this user
    if (
      response.result &&
      response.result.http &&
      response.result.http['401'] &&
      response.result.http['401'] === 'Password reset is required for user'
    ) {
      console.log('[AUTH] User reset password required');
      yield put(actions.needsResetPassword(storeState.login.username));
      return;
    } else {
      yield put(actions.needsResetPassword(null));
    }

    // console.log('DEBUG [AUTH]SAGA User auth data RESPONSE AFTER RESET PASS: ', { response, action });

    if (!('OK' === response.status)) {
      console.log('[AUTH] User auth error');
      yield put(actions.inRequest(false));
      yield put(actions.loginStep(1));
      yield put(actions.loginError(true));
      return;
    }

    authData = response.result;

    yield call(authenticateSaga, authData);

    yield localStorage.setItem('username', data.username);
    yield localStorage.setItem('method', method);

    yield put(actions.inRequest(false));
    yield put(actions.setLoginPassword('', false, false));
  } catch (error) {
    yield put(actions.inRequest(false));
    yield put(actions.loginStep(1));
    // yield put(actions.loginUsernameError(true));
    // yield put(actions.loginPasswordError(true));
    yield put(actions.loginError(true));
    return;
  }
}

export function* logoutSaga(action) {
  try {
    const storeState = ClientAPI.getStore().getState();
    if (storeState.profile.global_player_id && storeState.profile.client_player_id !== '') {
      window.dataLayer.push({
        user_id: storeState.profile.client_player_id,
        event: 'clearUserId',
      });
      window.dataLayer.push({ user_id: undefined });
    }

    // localStorage.removeItem('playerId');
    document.cookie = 'player_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'avatar_code=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'avatar_url=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'profile_nickname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  } catch (e) {
    /**/
  }

  yield call(clearAuthStorage);
  yield put(actions.clearAuthentication());
  yield put(actions.startAppAuth());
  yield put(clearLoyaltyState());
  yield put(clearMomentumState(false));
  yield put(resetRegister());
  yield put(actions.resetWallet());
}

export function* updateSmsEta(action) {
  const storeState = ClientAPI.getStore().getState();

  if (storeState.login.allowSMS) {
    return;
  }

  if ('user' === storeState.authentication.auth_type) {
    return;
  }

  let eta = action >= 0 ? action : action.eta;
  let newEta = eta - 1;
  if (newEta < 0) {
    return;
  }
  yield put(actions.smsEtaValue(newEta));
  yield delay(1000);
  yield call(updateSmsEta, newEta);
}
