import React from 'react';

import { useAppSelector } from '../../store';

import Breadcrumbs from '@/components/modules/breadcrumbs';
import GeneralSearch from '@/components/modules/general-search';

import ChangePhoneNumber from '@/components/modules/edit-phone-number';
import ChangePassword from '@/components/modules/change-password';
import TakeABreak from '@/components/modules/take-a-break';
import Limits from '@/components/modules/limits';
import Login from '@/components/modules/login';
import Wheel from '@/components/modules/wheel';
import WheelSimple from '@/components/modules/wheel-simple';
import PendingWithdrawals from '@/components/modules/pending-withdrawals';
import TransactionsHistory from '@/components/modules/transactions-history';
import TournamentGroup from '@/components/modules/tournament-group';
import Jackpot from '@/components/modules/jackpot';
import Jackpots from '@/components/modules/jackpots';
import JackpotsMeta from '@/components/modules/jackpotsMeta';
import GameHeader from '@/components/modules/game-header';
import LanguageSwitcher from '@/components/modules/language-switcher';
import HappyHour from '@/components/modules/happy-hour';
import Deposit from '@/components/modules/deposit';
import HappyHourWinPopup from '@/components/modules/happy-hour-win-popup';
import WalletBonuses from '@/components/modules/wallet-bonuses';

import WalletPreview from '@/components/modules/wallet-preview';
import SlotGameLauncher from '@/components/modules/slot-game-launcher';
import LiveGameLauncher from '@/components/modules/live-game-launcher';
import CurrencySelector from '@/components/modules/currency-selector';
import CountrySelector from '@/components/modules/country-selector';
import Withdraw from '@/components/modules/withdraw';
import ForgotPassword from '@/components/modules/forgot-password';
import Documents from '@/components/modules/documents';
import ProfileSettings from '@/components/modules/profile-settings';
import RegisterLegacy from '@/components/modules/register/register-legacy';
import RegisterStepper from '@/components/modules/register/register-stepper';

import DataSourceTools from '@/components/modules/data-source-tools';
import RankSystems from '@/components/modules/rank-systems';

import MomentumGameHeader from '@/components/modules/momentum/momentum-game-header';
import MomentumGameHeaderNotification from '@/components/modules/momentum/momentum-game-header-notification';
import MomentumLobby from '@/components/modules/momentum/momentum-lobby';
import MomentumWallet from '@/components/modules/momentum/momentum-wallet';
import MomentumProgressAnimation from '@/components/modules/momentum/momentum-progress-animation';
import MomentumWinAnimated from '@/components/modules/momentum/momentum-win-animated';
import MomentumWinNonAnimated from '@/components/modules/momentum/momentum-win-non-animated';

import ChangeProfileDetails from '@/components/modules/change-profile-details';

import PopupBonuses from '@/components/modules/popup-bonuses';

// bets
import PrematchSport from '@/components/modules/bets/prematch/sport';
import PrematchCalendar from '@/components/modules/bets/prematch/calendar';
import PrematchNavigator from '@/components/modules/bets/prematch/navigator';
import PrematchNavigatorDesktop from '@/components/modules/bets/prematch/navigator-desktop';
import LiveSport from '@/components/modules/bets/live/sport';
import LiveNavigator from '@/components/modules/bets/live/navigator';
import LiveNavigatorDesktop from '@/components/modules/bets/live/navigator-desktop';
import BetsFull from '@/components/modules/bets/betslip/body';
import BetslipSwitch from '@/components/modules/bets/betslip/betslip-switch';
import SoldCard from '@/components/modules/bets/betslip/soldcard';
import MatchDetails from '@/components/modules/bets/match-details';
import Arrow from '@/components/modules/bets/arrow';
import InPageMatchDetails from '@/components/modules/bets/in-page-match-details';
import Bet from '@/components/modules/bets/bet';
import PopupBetslip from '@/components/modules/bets/betslip/popup-betslip';
import QuickBet from '@/components/modules/bets/betslip/quick-bet';
import MultiBet from '@/components/modules/bets/betslip/multi-bet';
import BetTicketModal from '@/components/modules/bets/betslip/ticket-modal';
import EnableWinnerFun from '@/components/modules/bets/enable-winnerfun';
import WinnerFunModal from '@/components/modules/bets/winner-fun-modal';
import BetBuilder from '@/components/modules/bets/bet-builder';
import BetBuilderSummary from '@/components/modules/bets/bet-builder-summary';

import MatchCard from '@/components/modules/bets/match-card';
import DeleteBets from '@/components/modules/bets/betslip/delete-bets';

import MyTicketsBetsOpened from '@/components/modules/my-tickets/bets-opened';
import MyTicketsBetsSettled from '@/components/modules/my-tickets/bets-settled';
import MyTicketsWinnerFunBetsOpened from '@/components/modules/my-tickets/wf-bets-opened';
import MyTicketsWinnerFunBetsSettled from '@/components/modules/my-tickets/wf-bets-settled';
import MyTicketsState from '@/components/modules/my-tickets/state';
import MyTicketsToggler from '@/components/modules/my-tickets/toggler';

import TournamentBetslip from '@/components/modules/tournament-betslip';
import RewardsCardCarousel from '@/components/modules/rewards-card-carousel';

import LottoEvents from '@/components/modules/lotto/events';
import LottoEventDetails from '@/components/modules/lotto/event-details';
import LottoSystems from '@/components/modules/lotto/systems';
import LottoPromotedEvents from '@/components/modules/lotto/promoted';
import LottoBetslipBody from '@/components/modules/lotto/betslip/body';
import LottoSoldCard from '@/components/modules/lotto/betslip/soldcard';
import LottoTicketModal from '@/components/modules/lotto/betslip/ticket-modal';
import LottoPopupBetslip from '@/components/modules/lotto/betslip/popup-betslip';
import LottoQuickBet from '@/components/modules/lotto/betslip/quick-bet';
import LottoClearBetslip from '@/components/modules/lotto/betslip/delete-bets';
import LottoTicketsOpened from '@/components/modules/my-tickets/lotto-opened';
import LottoTicketsSettled from '@/components/modules/my-tickets/lotto-settled';
import LottoDrawing from '@/components/modules/lotto/lotto-drawing';

import DataSplitter from '@/components/modules/data-splitter';

import Observer from '@/components/modules/observer';

import MultiBetOfTheDay from '@/components/modules/multi-bet-of-the-day';

import UltraOdds from '@/components/modules/ultra-odds';
import Calendar from '@/components/modules/calendar';

import PathChange from '@/components/modules/path-change';
import GameRules from '@/components/modules/game-rules';
import Cookies from '@/components/modules/cookies';
import ExitIntentHandler from '@/components/modules/exit-intent-handler';
import PromoBubble from '@/components/modules/promo-bubble';
import TimeSpentPopup from '@/components/modules/time-spent-popup';

import Social from '@/components/modules/social';
import SocialHub from '@/components/modules/social-hub';
import SocialHubToggler from '@/components/modules/social-hub/toggler';
import SocialHubChat from '@/components/modules/social-hub/chat';
import SocialHubFeedPost from '@/components/modules/social-hub/feed/post';
import SocialHubFeedNewPost from '@/components/modules/social-hub/feed/post/new';
import SocialHubFeedPostPoll from '@/components/modules/social-hub/feed/post/poll';
import SocialHubMessageInput from '@/components/modules/social-hub/message-input';

import FavoriteGames from '@/components/modules/favorite-games';
import SimpleSwitch from '@/components/modules/simple-switch';
import SimpleTabsSwitch from '@/components/modules/simple-tabs-switch';

import BetsMatchStats from '@/components/modules/bets-match-stats';
import BetsLeagueStats from '@/components/modules/bets-league-stats';
import BetsLeagueMatchStats from '@/components/modules/bets-league-match-stats';
import BetsLeagueMatches from '@/components/modules/bets-league-matches';
import Pagination from '@/components/modules/pagination';
import EmailValidation from '@/components/modules/email-validation';
import EuroPopup from '@/components/modules/euro-popup';

import Airdrops from '@/components/modules/airdrops';
import Challenges from '@/components/modules/challenges';

import BetsSharedTicket from '@/components/modules/bets-shared-ticket';

import LiveStats from '@/components/modules/live-stats';

import Chest from '@/components/modules/chest';

import SlotGameLauncherInfo from '@/components/modules/slot-game-launcher-info';
import SlideToHideToolbar from '@/components/modules/slide-to-hide-toolbar';

import UKBetsSelector from '@/components/modules/bets/bets-selector';
import BetsResolveUrl from '@/components/modules/bets/resolve-url';
import BetsOddLimiter from '@/components/modules/bets/odd-limiter';

import ListIteration from '@/components/modules/list-iteration';
import RecommendedBet from '@/components/modules/bets-recommendation/recommended-bet';
import RecommendedBets from '@/components/modules/bets-recommendation/recommended-bets';

import HotspinsJackpot from '@/components/modules/hotspins-jackpot';
import VideoReel from '@/components/video-reels';

import OmniplayLotto from '@/components/modules/omniplay-lotto';

import AviatorAnimation from '@/components/modules/aviator-animation';

import CheckD from '@/components/modules/checkd';
import Register from '@/components/modules/register/register-dynamic';

import Poker from '@/components/modules/poker';

import Super8 from '@/components/modules/super8';
import BetFeed from '@/components/modules/bet-feed';

import FilesUploader from '@/components/modules/files-uploader';

/*

const ChangePhoneNumber = React.lazy(() => import('@/components/modules/edit-phone-number'));
const ChangePassword = React.lazy(() => import('@/components/modules/change-password'));
const TakeABreak = React.lazy(() => import('@/components/modules/take-a-break'));
const Limits = React.lazy(() => import('@/components/modules/limits'));
const Login = React.lazy(() => import('@/components/modules/login'));
const Wheel = React.lazy(() => import('@/components/modules/wheel'));
const WheelSimple = React.lazy(() => import('@/components/modules/wheel-simple'));
const PendingWithdrawals = React.lazy(() => import('@/components/modules/pending-withdrawals'));
const TransactionsHistory = React.lazy(() => import('@/components/modules/transactions-history'));
const TournamentGroup = React.lazy(() => import('@/components/modules/tournament-group'));
const Jackpot = React.lazy(() => import('@/components/modules/jackpot'));
const Jackpots = React.lazy(() => import('@/components/modules/jackpots'));
const JackpotsMeta = React.lazy(() => import('@/components/modules/jackpotsMeta'));
const GameHeader = React.lazy(() => import('@/components/modules/game-header'));
const LanguageSwitcher = React.lazy(() => import('@/components/modules/language-switcher'));
const HappyHour = React.lazy(() => import('@/components/modules/happy-hour'));
const Deposit = React.lazy(() => import('@/components/modules/deposit'));
const HappyHourWinPopup = React.lazy(() => import('@/components/modules/happy-hour-win-popup'));
const WalletBonuses = React.lazy(() => import('@/components/modules/wallet-bonuses'));
const WalletPreview = React.lazy(() => import('@/components/modules/wallet-preview'));
const SlotGameLauncher = React.lazy(() => import('@/components/modules/slot-game-launcher'));
const LiveGameLauncher = React.lazy(() => import('@/components/modules/live-game-launcher'));
const CurrencySelector = React.lazy(() => import('@/components/modules/currency-selector'));
const CountrySelector = React.lazy(() => import('@/components/modules/country-selector'));
const Withdraw = React.lazy(() => import('@/components/modules/withdraw'));
const ForgotPassword = React.lazy(() => import('@/components/modules/forgot-password'));
const Documents = React.lazy(() => import('@/components/modules/documents'));
const ProfileSettings = React.lazy(() => import('@/components/modules/profile-settings'));
const Register = React.lazy(() => import('@/components/modules/register'));
const DataSourceTools = React.lazy(() => import('@/components/modules/data-source-tools'));
const RankSystems = React.lazy(() => import('@/components/modules/rank-systems'));

const Momentum = React.lazy(() => import('@/components/modules/momentum'));
const ChangeProfileDetails = React.lazy(() => import('@/components/modules/change-profile-details'));
const PopupBonuses = React.lazy(() => import('@/components/modules/popup-bonuses'));

// bets
const PrematchSport = React.lazy(() => import('@/components/modules/bets/prematch/sport'));
const PrematchCalendar = React.lazy(() => import('@/components/modules/bets/prematch/calendar'));
const PrematchNavigator = React.lazy(() => import('@/components/modules/bets/prematch/navigator'));
const PrematchNavigatorDesktop = React.lazy(() => import('@/components/modules/bets/prematch/navigator-desktop'));
const LiveSport = React.lazy(() => import('@/components/modules/bets/live/sport'));
const LiveNavigator = React.lazy(() => import('@/components/modules/bets/live/navigator'));
const LiveNavigatorDesktop = React.lazy(() => import('@/components/modules/bets/live/navigator-desktop'));
const BetsFull = React.lazy(() => import('@/components/modules/bets/betslip/body'));
const BetslipSwitch = React.lazy(() => import('@/components/modules/bets/betslip/betslip-switch'));
const SoldCard = React.lazy(() => import('@/components/modules/bets/betslip/soldcard'));
const MatchDetails = React.lazy(() => import('@/components/modules/bets/match-details'));
const Arrow = React.lazy(() => import('@/components/modules/bets/arrow'));
const InPageMatchDetails = React.lazy(() => import('@/components/modules/bets/in-page-match-details'));
const Bet = React.lazy(() => import('@/components/modules/bets/bet'));
const PopupBetslip = React.lazy(() => import('@/components/modules/bets/betslip/popup-betslip'));
const QuickBet = React.lazy(() => import('@/components/modules/bets/betslip/quick-bet'));
const MultiBet = React.lazy(() => import('@/components/modules/bets/betslip/multi-bet'));
const BetTicketModal = React.lazy(() => import('@/components/modules/bets/betslip/ticket-modal'));
const EnableWinnerFun = React.lazy(() => import('@/components/modules/bets/enable-winnerfun'));
const WinnerFunModal = React.lazy(() => import('@/components/modules/bets/winner-fun-modal'));
const BetBuilder = React.lazy(() => import('@/components/modules/bets/bet-builder'));
const BetBuilderSummary = React.lazy(() => import('@/components/modules/bets/bet-builder-summary'));
const MatchCard = React.lazy(() => import('@/components/modules/bets/match-card'));
const DeleteBets = React.lazy(() => import('@/components/modules/bets/betslip/delete-bets'));

const MyTicketsBetsOpened = React.lazy(() => import('@/components/modules/my-tickets/bets-opened'));
const MyTicketsBetsSettled = React.lazy(() => import('@/components/modules/my-tickets/bets-settled'));
const MyTicketsState = React.lazy(() => import('@/components/modules/my-tickets/state'));
const MyTicketsToggler = React.lazy(() => import('@/components/modules/my-tickets/toggler'));

const TournamentBetslip = React.lazy(() => import('@/components/modules/tournament-betslip'));
const RewardsCardCarousel = React.lazy(() => import('@/components/modules/rewards-card-carousel'));

const LottoEvents = React.lazy(() => import('@/components/modules/lotto/events'));
const LottoEventDetails = React.lazy(() => import('@/components/modules/lotto/event-details'));
const LottoSystems = React.lazy(() => import('@/components/modules/lotto/systems'));
const LottoPromotedEvents = React.lazy(() => import('@/components/modules/lotto/promoted'));
const LottoBetslipBody = React.lazy(() => import('@/components/modules/lotto/betslip/body'));
const LottoSoldCard = React.lazy(() => import('@/components/modules/lotto/betslip/soldcard'));
const LottoTicketModal = React.lazy(() => import('@/components/modules/lotto/betslip/ticket-modal'));
const LottoPopupBetslip = React.lazy(() => import('@/components/modules/lotto/betslip/popup-betslip'));
const LottoQuickBet = React.lazy(() => import('@/components/modules/lotto/betslip/quick-bet'));
const LottoClearBetslip = React.lazy(() => import('@/components/modules/lotto/betslip/delete-bets'));
const LottoTicketsOpened = React.lazy(() => import('@/components/modules/my-tickets/lotto-opened'));
const LottoTicketsSettled = React.lazy(() => import('@/components/modules/my-tickets/lotto-settled'));
const LottoDrawing = React.lazy(() => import('@/components/modules/lotto/lotto-drawing'));

const DataSplitter = React.lazy(() => import('@/components/modules/data-splitter'));
const Observer = React.lazy(() => import('@/components/modules/observer'));
const MultiBetOfTheDay = React.lazy(() => import('@/components/modules/multi-bet-of-the-day'));
const UltraOdds = React.lazy(() => import('@/components/modules/ultra-odds'));
const Calendar = React.lazy(() => import('@/components/modules/calendar'));
const PathChange = React.lazy(() => import('@/components/modules/path-change'));
const GameRules = React.lazy(() => import('@/components/modules/game-rules'));
const Cookies = React.lazy(() => import('@/components/modules/cookies'));

const Social = React.lazy(() => import('@/components/modules/social'));
const SocialHubToggler = React.lazy(() => import('@/components/modules/social-hub/toggler'));
const SocialHubChat = React.lazy(() => import('@/components/modules/social-hub/chat'));

const FavoriteGames = React.lazy(() => import('@/components/modules/favorite-games'));
*/

export const ModuleElement = (componentProps) => {
  const config = useAppSelector((state) => state.templatesConfig);
  const dsType = componentProps.properties?.dsType ?? '';
  const det = dsType ? config.dataElementTypes[dsType] : null;

  let moduleKey = '';
  if (det && det.internal && det.internal.length) {
    const from = det.internal.find((el) => el.id === 'from');
    const path = det.internal.find((el) => el.id === 'path');
    if (from && from.value === 'module') {
      if (path && path.value) moduleKey = path.value;
    }
  }

  if (!moduleKey) return null;

  let content = null;

  switch (moduleKey) {
    case 'module.changePhoneNumber':
      content = <ChangePhoneNumber {...componentProps} />;
      break;
    case 'module.changePassword':
      content = <ChangePassword {...componentProps} />;
      break;
    case 'module.takeABreak':
      content = <TakeABreak {...componentProps} />;
      break;
    case 'module.limits':
      content = <Limits {...componentProps} />;
      break;
    case 'module.login':
      content = <Login {...componentProps} />;
      break;
    case 'module.wheel':
      content = <Wheel {...componentProps} />;
      break;
    case 'module.wheel.canSpinWheel':
      content = <WheelSimple {...componentProps} />;
      break;
    case 'module.pendingWithdrawals':
      content = <PendingWithdrawals {...componentProps} />;
      break;
    case 'module.transactionsHistory':
      content = <TransactionsHistory {...componentProps} />;
      break;
    case 'module.tournamentsGroup':
      content = <TournamentGroup {...componentProps} />;
      break;
    case 'module.jackpot':
      content = <Jackpot {...componentProps} />;
      break;
    case 'module.jackpots':
      content = <Jackpots {...componentProps} />;
      break;
    case 'module.jackpotsMeta':
      content = <JackpotsMeta {...componentProps} />;
      break;
    case 'module.gameHeader':
      content = <GameHeader {...componentProps} />;
      break;
    case 'module.languageSwitcher':
      content = <LanguageSwitcher {...componentProps} />;
      break;
    case 'module.happyHour':
      content = <HappyHour {...componentProps} />;
      break;
    case 'module.HappyHourWinPopup':
      content = <HappyHourWinPopup {...componentProps} />;
      break;
    case 'module.walletBonuses':
      content = <WalletBonuses {...componentProps} />;
      break;
    case 'module.deposit-bridger':
    case 'module.deposit':
      content = <Deposit {...componentProps} />;
      break;
    case 'module.register':
      content = <RegisterLegacy {...componentProps} />;
      break;
    case 'module.register-dynamic':
      content = <Register {...componentProps} />;
      break;
    case 'module.register-stepper':
      content = <RegisterStepper {...componentProps} />;
      break;
    case 'module.email-validation':
      content = <EmailValidation {...componentProps} />;
      break;
    case 'module.videoReels':
      content = <VideoReel {...componentProps} />;
      break;
    case 'module.slotGameLauncher':
      content = <SlotGameLauncher {...componentProps} />;
      break;
    case 'module.liveGameLauncher':
      content = <LiveGameLauncher {...componentProps} />;
      break;
    case 'module.walletPreview':
      content = <WalletPreview {...componentProps} />;
      break;
    case 'module.currencySelector':
      content = <CurrencySelector {...componentProps} />;
      break;
    case 'module.countrySelector':
      content = <CountrySelector {...componentProps} />;
      break;
    case 'module.withdraw':
      content = <Withdraw {...componentProps} />;
      break;
    case 'module.forgotPassword':
      content = <ForgotPassword {...componentProps} />;
      break;
    case 'module.documents':
      content = <Documents {...componentProps} />;
      break;
    case 'module.profileSettings':
      content = <ProfileSettings {...componentProps} />;
      break;
    case 'module.dataSourceTools':
      content = <DataSourceTools {...componentProps} />;
      break;
    case 'module.rankSystems':
      content = <RankSystems {...componentProps} />;
      break;
    case 'module.changePlayerProfileDetails':
      content = <ChangeProfileDetails {...componentProps} />;
      break;
    case 'module.game-rules':
      content = <GameRules {...componentProps} />;
      break;
    case 'module.cookies':
      content = <Cookies {...componentProps} />;
      break;
    case 'module.exit-intent-handler':
      content = <ExitIntentHandler {...componentProps} />;
      break;
    case 'module.promo-bubble':
      content = <PromoBubble {...componentProps} />;
      break;
    case 'module.time-spent-popup':
      content = <TimeSpentPopup {...componentProps} />;
      break;

    case 'module.momentum.progress-animation':
      content = <MomentumProgressAnimation {...componentProps} />;
      break;
    case 'module.momentum.win-animated':
      content = <MomentumWinAnimated {...componentProps} />;
      break;

    case 'module.momentum.win-non-animated':
      content = <MomentumWinNonAnimated {...componentProps} />;
      break;

    case 'module.momentum.game-header':
      content = <MomentumGameHeader {...componentProps} />;
      break;
    case 'module.momentum.game-header-notification':
      content = <MomentumGameHeaderNotification {...componentProps} />;
      break;
    case 'module.momentum.lobby':
      content = <MomentumLobby {...componentProps} />;
      break;
    case 'module.momentum.wallet':
      content = <MomentumWallet {...componentProps} />;
      break;

    case 'module.bets.match.stats':
      content = <BetsMatchStats {...componentProps} />;
      break;
    case 'module.bets.league.stats':
      content = <BetsLeagueStats {...componentProps} />;
      break;
    case 'module.bets.league.match.stats':
      content = <BetsLeagueMatchStats {...componentProps} />;
      break;
    case 'module.bets.league.matches':
      content = <BetsLeagueMatches {...componentProps} />;
      break;

    case 'module.bets.prematch.sport':
      content = <PrematchSport {...componentProps} />;
      break;
    case 'module.bets.prematch.calendar':
      content = <PrematchCalendar {...componentProps} />;
      break;
    case 'module.bets.prematch.navigator':
      content = <PrematchNavigator {...componentProps} />;
      break;
    case 'module.bets.prematch.navigator-desktop':
      content = <PrematchNavigatorDesktop {...componentProps} />;
      break;

    case 'module.bets.live.sport':
      content = <LiveSport {...componentProps} />;
      break;
    case 'module.bets.live.navigator':
      content = <LiveNavigator {...componentProps} />;
      break;
    case 'module.bets.live.navigator-desktop':
      content = <LiveNavigatorDesktop {...componentProps} />;
      break;

    case 'module.bets.betslip':
      content = <BetsFull {...componentProps} />;
      break;
    case 'module.bets.betslip-switch':
      content = <BetslipSwitch {...componentProps} />;
      break;
    // TODO: maybe? remove this case
    case 'module.bets.soldcard':
      content = <SoldCard {...componentProps} />;
      break;
    case 'module.bets.delete-bets':
      content = <DeleteBets {...componentProps} />;
      break;
    case 'module.bets.match-details':
      content = <MatchDetails {...componentProps} />;
      break;
    case 'module.bets.arrow':
      content = <Arrow {...componentProps} />;
      break;
    case 'module.bets.in-page-match-details':
      content = <InPageMatchDetails {...componentProps} />;
      break;
    case 'module.bets.betslip-popup':
      content = <PopupBetslip {...componentProps} />;
      break;
    case 'module.bets.quick-bet':
      content = <QuickBet {...componentProps} />;
      break;
    case 'module.bets.multi-bet':
      content = <MultiBet {...componentProps} />;
      break;
    case 'module.bets.ticket-modal':
      content = <BetTicketModal {...componentProps} />;
      break;
    case 'module.bets.bet-builder':
      content = <BetBuilder {...componentProps} />;
      break;
    case 'module.bets.bet-builder.summary':
      content = <BetBuilderSummary {...componentProps} />;
      break;

    case 'module.bets.bet':
      content = <Bet {...componentProps} />;
      break;
    case 'module.bets.enable-winnerfun':
      content = <EnableWinnerFun {...componentProps} />;
      break;
    case 'module.bets.winner-fun-modal':
      content = <WinnerFunModal {...componentProps} />;
      break;
    case 'module.tournamentBetslip':
      content = <TournamentBetslip {...componentProps} />;
      break;

    case 'module.breadcrumbs':
      content = <Breadcrumbs {...componentProps} />;
      break;

    case 'module.search':
      content = <GeneralSearch {...componentProps} />;
      break;

    case 'module.bets.my-tickets.bets-opened':
      content = <MyTicketsBetsOpened {...componentProps} />;
      break;
    case 'module.bets.my-tickets.bets-settled':
      content = <MyTicketsBetsSettled {...componentProps} />;
      break;
    case 'module.bets.my-tickets.wf-bets-opened':
      content = <MyTicketsWinnerFunBetsOpened {...componentProps} />;
      break;
    case 'module.bets.my-tickets.wf-bets-settled':
      content = <MyTicketsWinnerFunBetsSettled {...componentProps} />;
      break;
    case 'module.bets.my-tickets.state':
      content = <MyTicketsState {...componentProps} />;
      break;
    case 'module.rewardsCardCarousel':
      content = <RewardsCardCarousel {...componentProps} />;
      break;
    case 'module.bets.my-tickets.toggler':
      content = <MyTicketsToggler {...componentProps} />;
      break;

    case 'module.lotto.events':
      content = <LottoEvents {...componentProps} />;
      break;
    case 'module.lotto.event-details':
      content = <LottoEventDetails {...componentProps} />;
      break;
    case 'module.lotto.systems':
      content = <LottoSystems {...componentProps} />;
      break;
    case 'module.lotto.promoted':
      content = <LottoPromotedEvents {...componentProps} />;
      break;
    case 'module.lotto.betslip.body':
      content = <LottoBetslipBody {...componentProps} />;
      break;
    case 'module.lotto.betslip.soldcard':
      content = <LottoSoldCard {...componentProps} />;
      break;
    case 'module.lotto.betslip.ticket-modal':
      content = <LottoTicketModal {...componentProps} />;
      break;
    case 'module.lotto.my-tickets.lotto-opened':
      content = <LottoTicketsOpened {...componentProps} />;
      break;
    case 'module.lotto.my-tickets.lotto-settled':
      content = <LottoTicketsSettled {...componentProps} />;
      break;
    case 'module.lotto.betslip-popup':
      content = <LottoPopupBetslip {...componentProps} />;
      break;
    case 'module.lotto.quick-bet':
      content = <LottoQuickBet {...componentProps} />;
      break;
    case 'module.lotto.delete-bets':
      content = <LottoClearBetslip {...componentProps} />;
      break;
    case 'module.lotto.drawing':
      content = <LottoDrawing {...componentProps} />;
      break;

    case 'module.data-splitter':
      content = <DataSplitter {...componentProps} />;
      break;

    case 'module.bets.match-card':
      content = <MatchCard {...componentProps} />;
      break;

    case 'module.popup-bonuses':
      content = <PopupBonuses {...componentProps} />;
      break;

    case 'module.observer':
      content = <Observer {...componentProps} />;
      break;
    case 'module.multi-bet-of-the-day':
      content = <MultiBetOfTheDay {...componentProps} />;
      break;
    case 'module.ultra-odds':
      content = <UltraOdds {...componentProps} />;
      break;
    case 'module.calendar':
      content = <Calendar {...componentProps} />;
      break;
    case 'module.path-change':
      content = <PathChange {...componentProps} />;
      break;

    case 'module.social':
      content = <Social {...componentProps} />;
      break;
    case 'module.social-hub':
      content = <SocialHub {...componentProps} />;
      break;
    case 'module.social-hub.toggler':
      content = <SocialHubToggler {...componentProps} />;
      break;
    case 'module.social-hub.chat':
      content = <SocialHubChat {...componentProps} />;
      break;
    case 'module.social-hub.feed.post':
      content = <SocialHubFeedPost {...componentProps} />;
      break;
    case 'module.social-hub.feed.post.new':
      content = <SocialHubFeedNewPost {...componentProps} />;
      break;
    case 'module.social-hub.feed.poll':
      content = <SocialHubFeedPostPoll {...componentProps} />;
      break;
    case 'module.social-hub.message-input':
      content = <SocialHubMessageInput {...componentProps} />;
      break;

    case 'module.favorite-games':
      content = <FavoriteGames {...componentProps} />;
      break;

    case 'module.simple-switch':
      content = <SimpleSwitch {...componentProps} />;
      break;

    case 'module.simple-tabs-switch':
      content = <SimpleTabsSwitch {...componentProps} />;
      break;

    case 'module.pagination':
      content = <Pagination {...componentProps} />;
      break;

    case 'module.euro-popup':
      content = <EuroPopup {...componentProps} />;
      break;

    case 'module.airdrops':
      content = <Airdrops {...componentProps} />;
      break;

    case 'module.challenges':
      content = <Challenges {...componentProps} />;
      break;

    case 'module.bets.shared.ticket':
      content = <BetsSharedTicket {...componentProps} />;
      break;

    case 'module.live-stats':
      content = <LiveStats {...componentProps} />;
      break;

    case 'module.chest':
      content = <Chest {...componentProps} />;
      break;

    case 'module.slot-game-launcher-info':
      content = <SlotGameLauncherInfo {...componentProps} />;
      break;

    case 'module.slide-to-hide-toolbar':
      content = <SlideToHideToolbar {...componentProps} />;
      break;

    case 'module.uk-bets-selector':
      content = <UKBetsSelector {...componentProps} />;
      break;

    case 'module.bets.resolve-url':
      content = <BetsResolveUrl {...componentProps} />;
      break;

    case 'module.bets.odd-limiter':
      content = <BetsOddLimiter {...componentProps} />;
      break;

    case 'module.list.iteration':
      content = <ListIteration {...componentProps} />;
      break;

    case 'module.recommended.bet':
      content = <RecommendedBet {...componentProps} />;
      break;

    case 'module.recommended.bets':
      content = <RecommendedBets {...componentProps} />;
      break;

    case 'module.hotspins-jackpot':
      content = <HotspinsJackpot {...componentProps} />;
      break;

    case 'module.lotto.omniplay':
      content = <OmniplayLotto {...componentProps} />;
      break;

    case 'module.aviator-animation':
      content = <AviatorAnimation {...componentProps} />;
      break;

    case 'module.checkd':
      content = <CheckD {...componentProps} />;
      break;

    case 'module.poker':
      content = <Poker {...componentProps} />;
      break;

    case 'module.super8':
      content = <Super8 {...componentProps} />;
      break;

    case 'module.bet-feed':
      content = <BetFeed {...componentProps} />;
      break;

    case 'module.files.uploader':
      content = <FilesUploader {...componentProps} />;
      break;

    default:
      break;
  }

  if (!content) return null;

  return content;
  // return <React.Suspense fallback={null}>{content}</React.Suspense>;
};
