import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { cloneDeep } from 'lodash-es';
import { formatDate } from '../bets/utils/functions';
//import './index.scss';

type BetFeedProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetFeed = (componentProps: BetFeedProps) => {
  let props = componentProps;

  const { i18n } = useTranslation();
  const tid = React.useRef<any>(0);
  const accessToken = useAppSelector((state) => state.authentication?.access_token);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user') as boolean,
  );

  const [state, setState]: any = React.useState({
    data: null,
    loading: true,
    filters: {
      select: 'all',
      sort: 'most_played',
      limit: 20,
      sport_id: '1',
    },
  });

  const [navigator, setNavigator] = React.useState({
    selectedSport: null,
    hiddenSport: 0,
    extraTotal: 0,
  });

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const setLoading = React.useCallback((loading: boolean) => {
    setState((v: any) => ({
      ...v,
      loading,
    }));
  }, []);

  const onLoadData = React.useCallback(() => {
    setLoading(true);

    /**
      filters = Joi.object({
        sort: Joi.string().valid("most_played", "most_recent").default("most_played"),
        select: Joi.string().valid("all", "live", "prematch").default("all"),
        limit: Joi.number().max(100).default(20),
        sport_id: Joi.array().items(Joi.string()).default([]),
        category_id: Joi.array().items(Joi.string()).default([]),
        tournament_id: Joi.array().items(Joi.string()).default([]),
        market_id: Joi.array().items(Joi.string()).default([]),
      });
     */
    const postData = {
      ...state.filters,
    };

    if (postData.sport_id) {
      postData.sport_id = [postData.sport_id];
    }

    axios
      .post(`${window.config.betFeedApiUrl}/public/stakes`, postData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setState((v: any) => ({
          ...v,
          data: response.data,
          loading: false,
        }));

        setNavigator((v: any) => {
          if (v.selectedSport == null) {
            return {
              ...v,
              selectedSport: response.data.sports[0],
            };
          } else if (v.selectedSport) {
            const id = v.selectedSport.id;
            const sport = response.data.sports.find((s: any) => s.id === id);
            if (!sport) {
              return {
                ...v,
                selectedSport: response.data.sports[0],
              };
            }
          }

          return v;
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [state.filters, accessToken]);

  React.useEffect(() => {
    onLoadData();
  }, [state.filters, accessToken]);

  const handleResize = (timeout: any) => {
    window.clearTimeout(tid.current);
    tid.current = window.setTimeout(
      () => {
        let baseTop = 0;
        let items: any = document.querySelectorAll('.wl-prematch-navigator.mobile>.list-wrapper>.list>.item-wrapper');
        if (items.length) baseTop = items[0].offsetTop;

        const total = state.data?.sports?.length ?? 0;
        let extraTotal = 0;

        if (items) {
          items = Array.from(items);
          items.find((item: any, index: number) => {
            if (item.offsetTop > baseTop) {
              extraTotal = total - index;
              return true;
            }

            return false;
          });

          setNavigator((v: any) => ({
            ...v,
            extraTotal,
          }));
        }
      },
      typeof timeout === 'number' ? timeout : 100,
    );
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onAction = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    let type = e.currentTarget.getAttribute('data-type');
    const value = e.currentTarget.getAttribute('data-id');

    if (type === 'sport') type = 'sport_id';

    if (type && value) {
      setState((v: any) => ({
        ...v,
        filters: {
          ...v.filters,
          // @ts-ignore
          [type]: value,
        },
      }));
    }
  }, []);

  const contextValue = React.useMemo(() => {
    const data = state.data ? cloneDeep(state.data) : null;

    data?.stakes?.forEach?.((stake: any) => {
      const bfIdBet = stake.bfIdBet;
      const bfIdMbo = stake.bfIdMbo;

      let foundBet: any = null;
      let foundOutcome: any = null;

      stake.matchBets.forEach((bet: any) => {
        if (bet.idBet != bfIdBet) return;

        foundBet = bet;

        bet.mbOutcomes.forEach((outcome: any) => {
          if (outcome.idMbo != bfIdMbo) return;

          foundOutcome = outcome;
        });
      });

      if (foundBet) {
        stake.marketName = foundBet.mbDisplayName;
      }

      if (foundOutcome) {
        stake.oddName = foundOutcome.mboDisplayName;
        stake.oddValue = foundOutcome.mboOddValue;

        if (
          stake.marketName?.[0]?.includes?.('Final') ||
          stake.marketName?.[0]?.includes?.('Result') ||
          stake.marketName?.[2]?.includes?.('Final') ||
          stake.marketName?.[2]?.includes?.('Result')
        ) {
          if (
            stake.oddName?.[0]?.includes?.('1') ||
            stake.oddName?.[2]?.includes?.('1') ||
            stake.oddName?.[0]?.includes?.('Win1') ||
            stake.oddName?.[2]?.includes?.('Win1')
          ) {
            if (stake.team1Name[2] || stake.team1Name[0]) {
              stake.oddName = stake.team1Name;
            }
          }
          if (
            stake.oddName?.[0]?.includes?.('2') ||
            stake.oddName?.[2]?.includes?.('21') ||
            stake.oddName?.[0]?.includes?.('Win2') ||
            stake.oddName?.[2]?.includes?.('Win2')
          ) {
            if (stake.team2Name[2] || stake.team2Name[0]) {
              stake.oddName = stake.team2Name;
            }
          }
          // TODO: to add Draw instead X
        }
      }

      if (foundBet && foundOutcome) {
        stake.ids = {
          idBet: foundBet.idBet,
          idMb: foundBet.idMb,
          idBo: foundOutcome.idBo,
          idMbo: foundOutcome.idMbo,
        };
      }

      const teams = [];
      if (stake.team1Name[2] || stake.team1Name[0]) {
        teams.push(stake.team1Name);
      }
      if (stake.team2Name[2] || stake.team2Name[0]) {
        teams.push(stake.team2Name);
      }
      stake.eventName = teams.join(' vs ');
      stake.matchDateTimeString = formatDate(stake.matchDateTime, i18n.language);
    });

    return {
      data: data,
      navigator: navigator,
      filters: state.filters,
      actions: {
        onAction,
        onLoadData,
      },
    };
  }, [dataElementContext, componentProps, state, navigator, onAction, onLoadData, i18n.language]);

  console.log('BetFeed[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetFeed;
