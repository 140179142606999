import React, { useMemo } from 'react';
import {
  DEFAULT_TRIGGER_TIME,
  TYPE_ADDRESS,
  TYPE_CONFIRM_PASSWORD,
  TYPE_EMAIL,
  TYPE_FIRST_NAME,
  TYPE_LAST_NAME,
  TYPE_NICKNAME,
  TYPE_NIN,
  TYPE_OTP,
  TYPE_PASSWORD,
  TYPE_PHONE,
} from '@/components/modules/register/register-dynamic/const';
import NINValidator from '@/modules/casino/utils/NINValidator';
import PhoneNumberValidator from '@/modules/casino/utils/PhoneNumberValidator';
import PasswordValidator from '@/utils/PasswordValidator';
import { useTranslation } from 'react-i18next';
import { RegisterModuleStateProps } from '@/components/modules/register/register-dynamic/types';
import * as EmailValidator from 'email-validator';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';
import { useAppDispatch, useAppSelector } from '@/store';
import ResponseErrorMessages from '@/modules/casino/store/errors/ResponseErrorMessages';
import { useRegisterConfig } from '@/components/modules/register/register-dynamic/hooks/useRegisterConfig';
import { validateField } from '@/modules/casino/store/actions';

export function useRegisterForm(
  state: RegisterModuleStateProps,
  setState: React.Dispatch<React.SetStateAction<RegisterModuleStateProps>>,
  properties: any,
) {
  const { errors, partialAccount } = useAppSelector<any>((state) => state.register);
  const { phoneValidationMessage } = useAppSelector<any>((state) => state.allCountries);
  const dispatch = useAppDispatch();
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);
  const druid: { inProgress: boolean; data: { [key: string]: any } } = useAppSelector<any>((state) => state.druid);
  const { currentCountry } = useAppSelector<any>((state) => state.allCountries);
  const { t } = useTranslation();

  const config = useRegisterConfig(state, setState, properties?.config);

  React.useEffect(() => {
    const needsValidation: { type: string; value: string }[] = [];
    setState((prevState) => {
      const loadedFields = loadRegisterFieldsFromStorage();
      const fields = { ...prevState.fields };
      const checkboxes = { ...prevState.checkboxes };

      loadedFields &&
        Object.keys(loadedFields).forEach((key) => {
          // @ts-ignore
          if (fields[key]) {
            // @ts-ignore
            if (fields[key].type === 'field') {
              // @ts-ignore
              fields[key].value = loadedFields[key].value;
              // @ts-ignore
              needsValidation.push({ type: key, value: loadedFields[key].value });
              // @ts-ignore
            } else if (fields[key].type === 'checkbox') {
              // @ts-ignore
              checkboxes[key].checked = loadedFields[key].value;
            }
          }
        });

      return {
        ...prevState,
        // ...initialState,
        fields: fields,
        checkboxes: checkboxes,
        trigger: 'useEffect-[]',
      };
    });

    needsValidation.forEach((field) => {
      triggerValidator(field.type, field.value, 0);
    });
  }, []);

  React.useEffect(() => {
    const errorMessage = 'REGISTER.INPUT.ERROR.OCR-FAILED';
    if (!druid.inProgress && druid.data.ResultId) {
      const globalConfidence = 0.6;

      let readingOK = druid.data.Status === 'Complete';
      if (readingOK) {
        // check confidence for each field
        readingOK =
          druid.data.Confidence >= globalConfidence &&
          druid.data.Data['Face.Confidence'] >= globalConfidence &&
          druid.data.Data['Nume.Confidence'] >= globalConfidence &&
          druid.data.Data['Prenume.Confidence'] >= globalConfidence &&
          druid.data.Data['CNP.Confidence'] >= globalConfidence &&
          druid.data.Data['Domiciliu.Confidence'] >= globalConfidence;

        if (readingOK) {
          setState((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              firstName: {
                ...prevState.fields.firstName,
                value: druid.data.Data.Prenume,
              },
              lastName: {
                ...prevState.fields.lastName,
                value: druid.data.Data.Nume,
              },
              nin: {
                ...prevState.fields.nin,
                value: druid.data.Data.CNP,
              },
              address: {
                ...prevState.fields.address,
                value: druid.data.Data.Domiciliu,
              },
            },
            trigger: 'druid',
          }));

          triggerValidator(TYPE_FIRST_NAME, druid.data.Data.Prenume, 0);
          triggerValidator(TYPE_LAST_NAME, druid.data.Data.Nume, 0);
          triggerValidator(TYPE_NIN, druid.data.Data.CNP, 0);
          triggerValidator(TYPE_ADDRESS, druid.data.Data.Domiciliu, 0);
        }
      }

      setState((prevState) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          ocr_scan: {
            ...prevState.fields.ocr_scan,
            value: readingOK ? druid.data.ResultId : null,
            error: readingOK ? undefined : errorMessage,
          },
        },
        OCRModalOpen: false,
        trigger: 'druid-failed',
      }));
    } else {
      if (druid.data.error) {
        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            ocr_scan: {
              ...prevState.fields.ocr_scan,
              error: errorMessage,
            },
          },
          OCRModalOpen: false,
          trigger: 'druid-failed-2',
        }));
      }
    }
  }, [druid]);

  React.useEffect(() => {
    // loop through errors and set state
    const fields = {
      ...state.fields,
    };
    Object.keys(errors).forEach((key) => {
      let error: boolean | string | undefined = undefined;
      if (errors[key]) {
        const lError = errors[key];
        if (typeof lError.ResponseCode !== 'undefined') {
          error = ResponseErrorMessages.get(lError.ResponseCode);
        } else {
          if (typeof lError === 'string') {
            error = lError;
          }
        }

        if (key === 'phone') {
          error = phoneValidationMessage;
        }
      }

      if (typeof error !== 'undefined') {
        // @ts-ignore
        fields[key].error = error;
      } else if (errors?.[key]?.length === 0) {
        // @ts-ignore
        fields[key].error = false;
      }
    });
    setState((prevState: RegisterModuleStateProps) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        ...fields,
      },
      loading: false,
      trigger: 'useEffect-errors',
    }));
  }, [errors, phoneValidationMessage]);

  React.useEffect(() => {
    if (partialAccount?.error) {
      if (state.currentStep === config.stepsMap.otp) {
        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            otp: {
              ...prevState.fields.otp,
              error: partialAccount.errorMessage ?? 'REGISTER.INPUT.ERROR.OTP-INVALID',
            },
          },
          loading: false,
          trigger: 'invalid otp',
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          currentStep: 0,
          errors: { ...prevState.errors, global: 'REGISTER.ERROR.UNKNOWN' },
          loading: false,
          trigger: 'unknown error',
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        currentStep: prevState.savedStep,
        loading: false,
        trigger: 'partialAccount state change',
      }));
    }
  }, [partialAccount]);

  React.useEffect(() => {
    saveRegisterFieldsInStorage();
  }, [state.fields, state.checkboxes]);

  const saveRegisterFieldsInStorage = () => {
    const fieldsToSave = ['email', 'phone', 'firstName', 'lastName', 'nin', 'address'];
    const checkboxesToSave = ['marketing_email', 'marketing_sms', 'marketing_phone', 'marketing_partner'];

    const fieldsData: { [key: string]: { value: string | boolean; type: 'field' | 'checkbox' } } = {};

    fieldsToSave.forEach((field) => {
      if (state.fields[field]) {
        fieldsData[field] = {
          value: state.fields[field]?.value,
          type: 'field',
        };
      }
    });
    checkboxesToSave.forEach((field) => {
      if (state.checkboxes[field]) {
        fieldsData[field] = {
          value: state.checkboxes[field]?.checked,
          type: 'checkbox',
        };
      }
    });

    const storageValues = loadRegisterFieldsFromStorage();

    if (storageValues) {
      if (JSON.stringify(fieldsData) !== JSON.stringify(storageValues)) {
        ExpiringLocalStorage.set('registerDynamicFields', JSON.stringify(fieldsData));
      }
    }
  };

  /**
   * This function is called when the page is loaded
   * It loads the register fields from the local storage
   */
  const loadRegisterFieldsFromStorage = () => {
    const loadedFields = ExpiringLocalStorage.get('registerDynamicFields');

    if (loadedFields) {
      return JSON.parse(loadedFields);
    }
    return {};
  };

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.type;
    const field = event.target.name;
    if (type === 'checkbox') {
      const value = event.target.checked;
      setState((prevState: RegisterModuleStateProps) => ({
        ...prevState,
        checkboxes: {
          ...prevState.checkboxes,
          [field]: {
            ...prevState.checkboxes[field],
            checked: value,
          },
        },
        trigger: `onFieldChange-${field}`,
      }));
    } else {
      let value = event.target.value;

      if (field === 'email') {
        value = value.toLowerCase();
      }

      setState((prevState: RegisterModuleStateProps) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [field]: {
            ...prevState.fields[field],
            value: value,
            error: undefined,
          },
        },
        trigger: `onFieldChange-${field}`,
      }));
      triggerValidator(field, value, field.includes('password') ? 0 : DEFAULT_TRIGGER_TIME);
    }
  };

  const onFieldBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    const value = event.target.value;
    setState((prevState: RegisterModuleStateProps) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: {
          ...prevState.fields[field],
          focused: false,
          error: undefined,
        },
      },
      trigger: `onFieldBlur-${field}`,
    }));
    triggerValidator(field, value, 0);
  };

  const onFieldFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    setState((prevState: RegisterModuleStateProps) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: {
          ...prevState.fields[field],
          error: undefined,
          focused: true,
        },
      },
      trigger: `onFieldFocus-${field}`,
    }));
  };

  const triggerValidator = (type: string, value: string, time: number) => {
    // delayed validation to prevent multiple calls
    if (timer) {
      clearTimeout(timer);
    }

    const lTimer = setTimeout(() => {
      switch (type) {
        case TYPE_EMAIL:
          validateFieldLocal(type, value, EmailValidator);
          break;
        case TYPE_PHONE:
          validateFieldLocal(type, value, PhoneNumberValidator);
          break;
        case TYPE_PASSWORD:
          passwordValidator(value);
          break;
        case TYPE_CONFIRM_PASSWORD:
          validateFieldLocal(type, value, { validate: (value: string) => value === state.fields.password.value });
          break;
        case TYPE_NICKNAME:
          validateFieldLocal(type, value, { validate: (value: string) => value.length >= 4 && value.length <= 100 });
          break;
        case TYPE_FIRST_NAME:
        case TYPE_LAST_NAME:
          validateFieldLocal(type, value, { validate: (value: string) => value.length >= 3 && value.length <= 100 });
          break;
        case TYPE_NIN:
          validateFieldLocal(type, value, NINValidator);
          break;
        case TYPE_OTP:
          validateFieldLocal(type, value, { validate: (value: string) => value.length > 3 });
          break;
        default:
          break;
      }
    }, time);
    setTimer(lTimer);
  };

  const validateFieldLocal = (field: string, value: string, validator: any) => {
    if (value === '') {
      setState((prevState: RegisterModuleStateProps) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [field]: {
            ...prevState.fields[field],
            error: undefined,
          },
        },
        trigger: 'FieldValidator-empty',
      }));
      return;
    }
    const valid = field === 'phone' ? validator.validate(value, currentCountry) : validator.validate(value);
    console.log('valid', valid);
    if (!valid) {
      setState((prevState: RegisterModuleStateProps) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [field]: {
            ...prevState.fields[field],
            error: `REGISTER.INPUT.ERROR.${field.toUpperCase()}-INVALID`,
          },
        },
        trigger: 'fieldValidator-invalid',
      }));
    } else {
      if (properties[`${field}ServerValidation`]) {
        dispatch(validateField(value, field));
      } else {
        setState((prevState: RegisterModuleStateProps) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            [field]: {
              ...prevState.fields[field],
              error: undefined,
            },
          },
          trigger: 'emailValidator-valid',
        }));
      }
    }
  };

  const passwordValidator = (password: string) => {
    const passwordIsValid = PasswordValidator(password, t);

    setState((prevState: RegisterModuleStateProps) => ({
      ...prevState,
      passwordStrengthScore: passwordIsValid.score,
      passwordStrengthShortLabel: passwordIsValid.labels.short,
      passwordStrengthLongLabel: passwordIsValid.labels.long,
      passwordConditionsNotMet: passwordIsValid.conditionsNotMet,
      passwordMessagePlaceholders: passwordIsValid.placeholders,
      fields: {
        ...prevState.fields,
        password: {
          ...prevState.fields.password,
          error: passwordIsValid.success ? false : passwordIsValid.message,
        },
      },
      trigger: 'passwordValidator',
    }));
  };

  const getValues = (forSignUp: boolean) => {
    const data: { [key: string]: string | number } = {};
    Object.keys(state.fields).forEach((key) => {
      data[key] = state.fields[key].value;
    });
    if (forSignUp) {
      // default marketing is true, if not present in page
      let defaultMarketing = 1;
      // check if we have marketing field in checkboxes
      if (state.checkboxes.marketing) {
        defaultMarketing = state.checkboxes.marketing.checked ? 1 : 0;
      }
      if (state.checkboxes.marketing_email) {
        data.marketing_email = state.checkboxes.marketing_email.checked ? 1 : 0;
      } else {
        data.marketing_email = defaultMarketing;
      }
      if (state.checkboxes.marketing_phone) {
        data.marketing_phone = state.checkboxes.marketing_phone.checked ? 1 : 0;
      } else {
        data.marketing_phone = defaultMarketing;
      }
      if (state.checkboxes.marketing_sms) {
        data.marketing_sms = state.checkboxes.marketing_sms.checked ? 1 : 0;
      } else {
        data.marketing_sms = defaultMarketing;
      }
      if (state.checkboxes.marketing_partners) {
        data.marketing_partners = state.checkboxes.marketing_partners.checked ? 1 : 0;
      } else {
        data.marketing_partners = defaultMarketing;
      }

      data.marketingAgreement =
        data.marketing_email || data.marketing_phone || data.marketing_sms || data.marketing_partners;
    } else {
      delete data.password;
    }
    delete data.confirmPassword;

    return data;
  };

  const isNextButtonDisabled = () => {
    console.log('isNextButtonDisabled');
    if (config.stepsMap.tc === state.currentStep && !state.checkboxes.tc.checked) {
      return true;
    }

    let isDisabled = false;
    config.steps[state.currentStep].components.forEach((component) => {
      if (component.layout === 'input') {
        // @ts-ignore
        if (!state.fields[component.type]) {
          isDisabled = true;
          // @ts-ignore
        } else if (state.fields[component.type]?.error) {
          isDisabled = true;
          // @ts-ignore
        } else if (state.fields[component.type]?.value === '') {
          isDisabled = true;
        }
      }
    });
    return isDisabled;
  };

  return useMemo(
    () => ({
      onFieldChange,
      onFieldBlur,
      onFieldFocus,
      triggerValidator,
      getValues,
      isNextButtonDisabled,
    }),
    [onFieldChange, onFieldFocus, onFieldBlur, triggerValidator, getValues, isNextButtonDisabled],
  );
}