import * as React from 'react';

import './page.scss';
import { useAppSelector, useAppDispatch } from '../../store';
import { fetchElements } from '../../store/slices/dataElements';
import { fetchSources } from '../../store/slices/dataSources';
import craftJsParser from '../utils/craftJsParser';
import PageLoader from '../page-loader';
import PageMetaTags from '../page-meta-tags';
import parseDataSources from '../utils/parseDataSources';
import useMaintenanceGateway from '@/modules/maintenance/MaintenanceGateway';
import { clearCache } from '@/page-components/utils/reselect-utils';

type PageProps = {
  id: string;
  tagProps?: {
    [key: string]: any;
  };
};

const Page: React.FC<PageProps> = (props) => {
  const { id } = props;

  //console.log('Page[props]', props);

  const config = useAppSelector((state) => state.templatesConfig);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user') as boolean,
  );
  const isAuthInProgress = useAppSelector((state) => state.authentication.in_progress);
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);
  const dispatch = useAppDispatch();
  const dataSourcesLoaded = useAppSelector((state) => state.dataSources.loaded);

  const [doneRender, setDoneRender] = React.useState<any>(null);

  const action = useMaintenanceGateway();

  const page = config.pages[id];
  let projectData = page.projectData;

  if (action?.redirect && config?.setPages?.['/maintenance/default']?.page_id) {
    projectData = config.pages[config.setPages['/maintenance/default'].page_id].projectData;
  } else if (page && page.a && !authenticated && config.setPages && config.setPages['/login']) {
    if (config.setPages['/login'].page_layout_id) {
      projectData = config.pageLayouts[config.setPages['/login'].page_layout_id].projectData;
    } else {
      projectData = config.pages[config.setPages['/login'].page_id].projectData;
    }
  }

  React.useEffect(() => {
    if (authenticationToken) {
      const [dataSources, dataElements] = parseDataSources({
        craftState: projectData,
      });
      //console.log('dataSources', dataSources, dataElements);
      if (dataSources.length) {
        const noRefresh: any = [];
        const withRefresh: any = [];

        dataSources.forEach((ds) => {
          if (config.dataSources[ds] && config.dataSources[ds].enable_auto_refresh) {
            withRefresh.push(ds);
          } else {
            noRefresh.push(ds);
          }
        });

        dispatch(fetchSources({ ids: withRefresh }));
        dispatch(fetchSources({ ids: noRefresh }));
      }
      if (dataElements.length) {
        // @ts-ignore
        dispatch(fetchElements({ ids: dataElements }));
      }

      if (dataSources.length && dataElements.length) {
        setDoneRender(1);
      } else {
        setDoneRender(2);
      }
    }
  }, [projectData, authenticationToken, config.dataSources]);

  React.useEffect(() => {
    if (doneRender === 2) {
      try {
        window.setTimeout(() => {
          // @ts-ignore
          if (typeof window.sendDrEvent === 'function') {
            // @ts-ignore
            window.sendDrEvent({ type: 'render', detail: `page id ${id}` });
          }
        }, 10);
      } catch (e) {}
    }
  }, [doneRender]);

  React.useEffect(() => {
    if (doneRender === 1 && dataSourcesLoaded) {
      setDoneRender(2);
    }
  }, [doneRender, dataSourcesLoaded]);

  React.useEffect(() => {
    return () => {
      clearCache();
    };
  }, [id]);

  const data: any = React.useMemo(() => {
    //console.log('render page', id);
    if (action?.redirect && config?.setPages?.['/maintenance/default']?.page_id) {
      const content = craftJsParser({
        craftState: projectData,
        rootNodeId: 'ROOT',
        pageLayoutId: config.setPages['/maintenance/default'].page_layout_id,
        pageId: config.setPages['/maintenance/default'].page_id,
        pageType: 'page',
        options: {
          authenticated,
        },
        withProvider: true,
      });

      return { content: <React.Fragment>{content}</React.Fragment>, isPage: false };
    } else if (page.a && !authenticated) {
      if (isAuthInProgress) {
        return { content: <PageLoader />, isPage: false };
      }

      if (config.setPages && config.setPages['/login']) {
        const content = craftJsParser({
          craftState: projectData,
          rootNodeId: 'ROOT',
          pageLayoutId: config.setPages['/login'].page_layout_id,
          pageId: config.setPages['/login'].page_id,
          pageType: 'page',
          options: {
            authenticated,
          },
          withProvider: true,
        });

        return { content: <React.Fragment>{content}</React.Fragment>, isPage: false };
      }
    }

    const content = craftJsParser({
      craftState: projectData,
      rootNodeId: 'ROOT',
      pageId: id,
      pageType: 'page',
      options: {
        authenticated,
      },
      withProvider: true,
    });

    let meta = null;
    if (page.pmd && Object.keys(page.pmd).length) {
      meta = <PageMetaTags meta={page.pmd} />;
    } else {
      // just in case this were set by a previous page with metadata but this current page doesn't have metadata
      document.documentElement.classList.remove('light-mode', 'dark-mode');
    }

    return { content, meta, isPage: true };
  }, [id, isAuthInProgress, authenticated, action, config, projectData]);

  if (!data) {
    return null;
  }

  if (!data.isPage) {
    return data.content;
  }

  return (
    <React.Fragment>
      {data.meta}
      {data.content}
    </React.Fragment>
  );
};

export default Page;
