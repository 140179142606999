import React, { useContext } from 'react';
import styled from 'styled-components';
import { Virtuoso } from 'react-virtuoso';


import craftJsParser from '../../../components/utils/craftJsParser';
import { ModuleListItems } from '../ModuleListItems/ModuleListItems';
import { DataElementContext } from '../../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { getProjectDataFromContext, PageDataContext } from '@/components/utils/PageDataProvider';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsList: [],
    dsType: '',
  },
};

const ModuleListDiv = styled.div((props) => props.$styleText);

export const ModuleList = (componentProps) => {
  let props = componentProps;

  const pageDataContext = React.useContext(PageDataContext);

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  let { dsList } = props.properties;

  if (!dsList || !Array.isArray(dsList)) {
    dsList = [];
  }

  const parseNumber = React.useCallback((value) => {
    if (value == null) return null;
    if (typeof value === 'number') return value;
    if (typeof value === 'string') {
      const parsed = Number(value);
      if (!isNaN(parsed)) return parsed;
    }
    return null;
  }, []);

  const parseNumberOrJson = React.useCallback((value) => {
    if (value == null) return null;
    if (typeof value === 'number') return value;
    if (typeof value === 'string') {
      const parsed = Number(value);
      if (!isNaN(parsed)) return parsed;
      try {
        const parsedJson = JSON.parse(value);
        if (parsedJson) return parsedJson;
      } catch (err) { }
    }
    return 0;
  }, []);

  const pValue = React.useMemo(() => {
    let hasError = false;
    let content = null;

    try {
      const data = getProjectDataFromContext(pageDataContext);

      const dsWrapperId = data[props.nodeId].nodes[0];
      const dsWrapperData = data[dsWrapperId];

      const itemContent = craftJsParser({
        craftState: data,
        rootNodeId: dsWrapperData.nodes[0],
        pageId: props.refType === 'layout' ? null : props.refId,
        pageLayoutId: props.refType === 'layout' ? props.refId : null,
        pageType: props.refType,
      });

      if (props.properties.items != null && props.properties.items !== '' && !isNaN(Number(props.properties.items))) {
        const maxItems = Number(props.properties.items);
        dsList = dsList.slice(0, maxItems);
      }

      let rendered = null;
      if (props.properties.useVirtualization) {
        const style = {};
        if (parseNumber(props.properties.width) != null) {
          style.width = props.properties.width;
        }
        // if we are using window scroll, we don't want to set the height
        if (!props.properties.useWindowScroll) {
          if (parseNumber(props.properties.height) != null) {
            style.height = props.properties.height;
          }
        }

        const virtuosoProps = {};
        if (props.properties.itemSize && typeof props.properties.itemSize === 'function') {
          virtuosoProps.itemSize = props.properties.itemSize;
        }
        if (parseNumberOrJson(props.properties.overscan) != null) {
          virtuosoProps.overscan = parseNumberOrJson(props.properties.overscan);
        }
        if (parseNumberOrJson(props.properties.increaseViewportBy) != null) {
          virtuosoProps.increaseViewportBy = parseNumberOrJson(props.properties.increaseViewportBy);
        }
        if (parseNumber(props.properties.fixedItemHeight) != null) {
          virtuosoProps.fixedItemHeight = parseNumber(props.properties.fixedItemHeight);
        }
        if (parseNumber(props.properties.defaultItemHeight) != null) {
          virtuosoProps.defaultItemHeight = parseNumber(props.properties.defaultItemHeight);
        }

        rendered = <Virtuoso
          style={style}
          useWindowScroll={props.properties.useWindowScroll || undefined}
          data={dsList}
          computeItemKey={(index, item) => item.id || index}
          itemContent={(i, item) => (
            <DataElementContext.Provider value={{ ...item, _index: i, _length: dsList.length, parentContext: dataElementContext }}>
              {itemContent}
            </DataElementContext.Provider>
          )}
          {...virtuosoProps}
        />;
      } else {
        rendered =
          dsList?.map((item, i) => {
            return (
              <DataElementContext.Provider key={i} value={{ ...item, _index: i, _length: dsList.length, parentContext: dataElementContext }}>
                {itemContent}
              </DataElementContext.Provider>
            );
          }) || [];
      }

      content = <ModuleListItems {...dsWrapperData.props}>{rendered}</ModuleListItems>;
    } catch (err) {
      console.error(err);
      hasError = true;
    }

    return { content, hasError };
  }, [
    isVisible, dsList, pageDataContext,
    props,
    dataElementContext,
    parseNumber, parseNumberOrJson,
  ]);

  if (!isVisible) return null;
  if (pValue.hasError) return null;
  if (!pValue.content) return null;

  return (
    <ModuleListDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      {pValue.content}
    </ModuleListDiv>
  );
};
