// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { updateGamesCache } from '@/utils/game-info-api-cache';

import { RootState } from '../index';
import { listHasGamesDataSources, responseHasGamesDataSources, sortGamesDataSources } from './playerGamesOrder';

import { hardodedJackpotResp } from '@/_temp/hardcodedJackpotResp';
import urlonStringify from '@/utils/urlon';

const DEBUG = false;

type FetchConfigProps = {
  ids: string[];
};

type FetchConfigResult = {
  data: Map<string, any[]>;
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

export function onlyKeys(obj: any, includeKeys: string[]) {
  const result: any = {};
  Object.keys(obj).forEach((key: any) => {
    if (includeKeys.indexOf(key) > -1) {
      result[key] = obj[key];
    }
  });
  return result;
}

const apiUrl = window.config.dataSourceApiUrl;

const IS_RECOMMENDED_GAMES_ENABLED = window.config && window.config.recommendedGamesEnabled === '1';
const IS_REELS_RECOMMENDED_GAMES_ENABLED =
  window.config && window.config.casinoExplorer.isReelsRecommendedGamesEnabled === '1';

const requestingSources: any = {};

export const fetchSources = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'dataSources/list',
  async ({ ids }, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;
      const playerGamesOrder = state.playerGamesOrder.items;

      //moved this call so it happens independently of the other calls
      //if ((IS_RECOMMENDED_GAMES_ENABLED || IS_REELS_RECOMMENDED_GAMES_ENABLED) && listHasGamesDataSources(ids) && !playerGamesOrder.length) {
      //ids.push(window.config.dataSourceAllPlayerGames);
      //}

      const qParams = urlonStringify({ ids });
      if (requestingSources[qParams]) {
        return { data: [], success: true };
      }
      requestingSources[qParams] = true;

      const response = await axios.get(`${apiUrl}/resolve/sources`, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
        params: {
          q: qParams,
        },
      });

      // Sometimes the API replies with "no response set" string resulting in each string characted to be added to the object in state
      if (response.data && typeof response.data === 'object') {
        let data = { ...response.data };

        // for staging environment, we need to replace the jackpot data with hardcoded data since we don`t receive any provider data
        if (
          data.all_jackpots_api &&
          window.config.environment === 'staging' &&
          window.config.enableLocalJackpots === '1'
        ) {
          if (!data.all_jackpots_api?.data?.length) {
            data.all_jackpots_api = hardodedJackpotResp;
          } else {
            hardodedJackpotResp.data.forEach((group: any) => {
              const exists = data.all_jackpots_api.data.find((g: any) => g.group === group.group);
              if (!exists) {
                data.all_jackpots_api.data.push(group);
              }
            });
          }
        }

        if ((IS_RECOMMENDED_GAMES_ENABLED || IS_REELS_RECOMMENDED_GAMES_ENABLED) && responseHasGamesDataSources(data)) {
          data = { ...data, ...sortGamesDataSources(data, playerGamesOrder) };
        }

        delete requestingSources[qParams];
        return { data: data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch sources",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };

      return rejectWithValue(errResp);
    }
  },
);

export interface ConfigReducer {
  items: any;
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const dataSourcesSlice = createSlice({
  name: 'dataSources',
  initialState: <ConfigReducer>{
    items: {},
    loading: false,
    loaded: false,
    error: null,
  },
  reducers: {
    clearDataSources: (state) => {
      state.items = {};
      state.loading = false;
      state.loaded = false;
      state.error = null;
    },
    updateDataSourceJackpot: (state, action) => {
      Object.keys(state.items).forEach((key) => {
        if (
          key &&
          state.items[key] &&
          state.items[key].element_type &&
          state.items[key].element_type.type_id === 'module.jackpotV2Group'
        ) {
          const groupUpdate = action.payload.data;
          const data = [...JSON.parse(JSON.stringify(state.items[key].data))];

          groupUpdate.forEach((group: any) => {
            const groupIndex = data.findIndex((ob) => {
              if (ob.group === group.group) {
                return true;
              }
              return false;
            });

            if (groupIndex > -1) {
              group.jackpots.forEach((jackpot: any) => {
                const jackpotIndex = data[groupIndex].jackpots.data.findIndex((el: any) => {
                  return el.id === jackpot.id;
                });

                if (jackpotIndex > -1) {
                  const name = data[groupIndex].jackpots.data[jackpotIndex].accumulator_states.data.name;
                  let value = data[groupIndex].jackpots.data[jackpotIndex].accumulator_states.data.value;

                  let amount =
                    data[groupIndex].jackpots.data[jackpotIndex].accumulator_states.data.amounts?.[0]?.amount;

                  jackpot.accumulator_states.find((j: any) => {
                    if (j.name === name) {
                      value = j.value;
                      amount = j.amounts?.[0]?.amount;
                      return true;
                    }
                    return false;
                  });

                  data[groupIndex].jackpots.data[jackpotIndex].accumulator_states.data.value = value;

                  if (data[groupIndex].jackpots.data[jackpotIndex].accumulator_states.data.amounts?.length) {
                    data[groupIndex].jackpots.data[jackpotIndex].accumulator_states.data.amounts[0].amount = amount;
                  }

                  data[groupIndex].jackpots.data[jackpotIndex] = {
                    ...data[groupIndex].jackpots.data[jackpotIndex],
                    ...onlyKeys(data[groupIndex].jackpots.data[jackpotIndex], [
                      'state',
                      'state_time',
                      'start_date',
                      'win_trigger_type',
                      'guaranteed_win',
                      'win_date',
                      'win_value',
                      'largest_win_amount',
                      'largest_win_date',
                      'largest_win_player',
                      'last_win_amount',
                      'last_win_date',
                      'last_win_player',
                    ]),
                  };
                }
              });
            }
          });

          state.items[key].data = data;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSources.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;

        if (action.payload.success) {
          state.items = { ...state.items, ...action.payload.data };
        }

        if (action.payload.data) {
          Object.keys(action.payload.data).forEach((sId: any) => {
            const ds = action.payload.data[sId];
            if (ds != null && ds.element_type?.type_id === 'slot_game') {
              updateGamesCache(ds.data, sId);
            }
          });
        }

        DEBUG && console.log('fetchSources.fulfilled', action.payload);
      })
      .addCase(fetchSources.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;

        DEBUG && console.log('fetchSources.rejected', action.payload);
      });
  },
});

export const { clearDataSources, updateDataSourceJackpot } = dataSourcesSlice.actions;

export default dataSourcesSlice.reducer;
